<template>
  <ul class="catlist relative-left full-width flex row wrap items-center x-center bullets-none">
    <li
      v-for="(item, index) of dbStore.gallery"
      :key="index"
      class="catlist-item"
    >
      <figure class="catlist-img relative-left full-width">
        <img
          :src="item.imgUrl"
          :alt="item.title"
          style="object-fit: cover; width: 100%; height: 100%"
        />
      </figure>
      <div class="caption flex row wrap items-center x-center full-width bg-white padding-left-right-10">
        <div class="txt-center">
          <h4 class="margin-bottom-5">{{ item.title }}</h4>
          <p v-if="item.description != ''">{{ item.description }}</p>
        </div>
      </div>
    </li>
  </ul>
</template>
<script>
import { useDbStore } from "@/stores/dbStore";
import { reactive, toRefs } from "vue";
export default {
  setup() {
    const dbStore = useDbStore();
    const dataCatálogo = reactive({});

    return {
      ...toRefs(dataCatálogo),
      dbStore,
    };
  },
};
</script>
