<template>
  <section class="obras-intro">
    <article class="obras">
      <div class="generalbluecard">
        <h2 class="generalcardtitle">
          {{ dbStore.obrasIntro.firstSectionTitle }}
        </h2>
      </div>
      <div class="generalrightcard">
        <div class="generalcardwrap">
          <h3 class="generalcardtext1 margin-bottom-20">
            {{ dbStore.obrasIntro.firstSectionSubtitle }}
          </h3>
          <div
            v-for="(item, index) of dbStore.obrasIntro.firstSectionList"
            :key="index"
            class="cardlist flex items-center"
          >
            
            <p class="generalcardtext uppercase margin-top-bottom-5">
              <span class="material-icons-outlined gray-dark-light">grid_view</span>
              <span class="generalcardtext-line2">
              {{ item.item }}</span>
            </p>
          </div>
        </div>
      </div>
    </article>
  </section>
  <section class="obras-gallery">
    <article class="container container_md">
      <Catalogo />
    </article>
  </section>
  <div class="whitespace"></div>
</template>

<script>
import Catalogo from "@/components/share-tools/Catalogo.vue";
import { reactive, toRefs } from "vue";
import { useDbStore } from "@/stores/dbStore";
export default {
  name: "Obras",
  components: {
    Catalogo,
  },
  setup() {
    const dbStore = useDbStore();
    const dataProducts = reactive({});

    return {
      ...toRefs(dataProducts),
      dbStore,
    };
  },
};
</script>
